import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { OptionTypeEnum } from 'app/dialog/dialog-extract-setting/dialog-extract-setting.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'custom-multiple-selection',
  templateUrl: './custom-multiple-selection.component.html',
  styleUrls: ['./custom-multiple-selection.component.scss']
})
export class CustomMultipleSelectionComponent implements OnInit {
  @ViewChild('checkboxes', { static: false })
  checkboxes?: ElementRef;
  expanded = false;
  isCheckAllFilter: boolean = false;
  /**
   * label
   */
  labelContent: string = '';
  /**
   * list option checked out put
   */
  listOptionChecked: any = [];
  /**
   * list option data
   */
  @Input() listOption: any;
  /**
   * list option default
   */
  @Input() listOptionCheckedDefault: any;
  /**
   * true if is string object
   */
  @Input() isStringObjects: boolean;
  /**
   * type of select box to set key id
   */
  @Input() optionType: any;
  /**
   * subject reset content
   */
  @Input() resetLabelContent: Subject<boolean>;
  /**
   * subject loaded data input for component
   */
  @Input() loaded: Subject<boolean>;
  /**
   * subject submit data output for component
   */
  @Input() submitData: Subject<void>;
  /**
   * event emit data output
   */
  @Output() eventEmit = new EventEmitter<any>();
  //constant
  public readonly OptionTypeEnum = OptionTypeEnum;
  constructor(private translateService: TranslateService) {}
  ngOnInit(): void {
    // waiting for load data input success
    this.loaded?.subscribe(() => {
      this.listOptionChecked = this.listOptionCheckedDefault ?? [];
      if (this.isStringObjects) {
        this.listOptionChecked = Helper.createListObject(this.listOptionChecked);
      }
      // set checked option first time
      this.listOption = this.getDefaultChecked();
      this.getLabelContent();
    });
    // subject reset label content
    this.resetLabelContent?.subscribe(data => {
      if (data == this.optionType) {
        this.listOption?.forEach(option => {
          delete option.isChecked;
        });
        this.listOptionChecked = this.listOptionCheckedDefault ?? [];
        if (this.isStringObjects) {
          this.listOptionChecked = Helper.createListObject(this.listOptionChecked);
        }
        this.listOption = this.getDefaultChecked();
        this.getLabelContent();
      }
    });
    if (this.optionType == OptionTypeEnum.MEDIA_TYPE && !this.listOptionCheckedDefault?.length) {
      this.listOptionCheckedDefault.push(this.listOption[0]);
    }
    // emit data output when click apply
    this.submitData?.subscribe(() => this.emitEventDataOutput(this.listOptionChecked));
  }

  /*
   * show Check boxes
   */
  showCheckboxes() {
    if (!this.checkboxes || !this.listOption || !this.listOption.length) {
      return;
    }
    if (!this.expanded) {
      this.checkboxes.nativeElement.style.display = 'block';
      this.expanded = true;
      this.listOption = this.getDefaultChecked();
      this.controlCheckAll();
    } else {
      this.checkboxes.nativeElement.style.display = 'none';
      this.expanded = false;
    }
  }
  /*
   * lock Select Box
   */
  lockSelectBox() {
    if (!this.checkboxes) {
      return;
    }
    if (this.expanded) {
      this.checkboxes.nativeElement.style.display = 'none';
      this.expanded = false;
    }
  }
  /*
   * checked
   */
  checked(index: number) {
    this.listOption[index].isChecked = !this.listOption[index].isChecked;
    this.getLabelContent();
    this.controlCheckAll();
  }
  /*
   * check all Filter
   */
  checkAllFilter() {
    this.isCheckAllFilter = !this.isCheckAllFilter;
    this.listOption.forEach(element => {
      element.isChecked =
        this.optionType == OptionTypeEnum.MEDIA_TYPE ? (element.isDisabled ? false : this.isCheckAllFilter) : this.isCheckAllFilter;
    });
    this.getLabelContent();
  }

  /**
   * control Check All
   */
  controlCheckAll() {
    this.isCheckAllFilter = this.listOption.every(filter => filter?.isChecked);
  }
  /**
   * get Name Label
   */
  getLabelContent() {
    this.labelContent = '';
    this.listOptionChecked = this.listOption?.filter(option => option.isChecked);
    if (this.listOption?.every(data => data.isChecked)) {
      this.labelContent =
        this.optionType == OptionTypeEnum.DEVICE
          ? this.translateService.instant('dialog-extraction-condition-setting.select-all-device')
          : this.translateService.instant('dialog-extraction-condition-setting.select-all');
    } else {
      this.labelContent =
        this.optionType == OptionTypeEnum.DEVICE
          ? this.listOptionChecked?.map(option => option.deviceId).join(',')
          : this.listOptionChecked?.map(option => option.name).join(',');
    }
  }

  /**
   * emit data
   * @param listOptionChecked
   */
  private emitEventDataOutput(listOptionChecked) {
    if (this.isStringObjects) {
      this.eventEmit.emit(listOptionChecked?.map(option => option.name));
    } else {
      this.eventEmit.emit(listOptionChecked);
    }
  }

  /**
   * set Default checked
   */
  getDefaultChecked(): any {
    if (!this.listOptionChecked || !this.listOptionChecked.length) {
      return this.listOption;
    }
    if (this.isStringObjects) {
      this.listOption?.forEach(element => {
        if (this.listOptionChecked.some((option: any) => option.name == element.name)) {
          element.isChecked = true;
        }
      });
    } else {
      this.listOption?.forEach(element => {
        if (this.listOptionChecked.some((option: any) => option.id == element.id)) {
          element.isChecked = true;
        }
      });
    }
    return this.listOption;
  }
}
